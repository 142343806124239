<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-img contain src="../assets/Images/wti-banner.jpg">
          <v-layout wrap fill-height class="hidden-xs-only">
            <v-flex xs12 text-center align-self-center pt-10>
              <span class="oswaldbold" style="color:white;font-size:60px">HELP US KEEP WILDLIFE FOREVER WILD</span>

            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11 pt-13>
            <v-layout wrap justify-end>
              <v-flex xs1 text-right @click="navigateToHome">
                <v-btn plain color="#e27725">
                  <v-icon size="230%">mdi-home</v-icon>

                </v-btn>
              </v-flex>
              <!-- <v-flex xs1 pl-6>
                <a @click="scrollToFAQ">
                  <v-btn color="#e27725">
                    <span class="popregular medium" style="color:white">
                      FAQ'S
                    </span>
                  </v-btn>
                </a>


              </v-flex> -->
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 md7 pt-12>
            <v-layout wrap>

              <v-flex xs12 pl-2 pb-6 pb-sm-4 pb-md-0 text-left>
                <span class="popregular medium" style="color: black;font-weight:bold;font-size: 26px;">Rehabilitation
                  Success Stories
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-6 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  <span style="font-weight:bold">
                    GANGA THE RHINO
                  </span>
                  – Back in 2004, ‘Ganga’, a female rhino calf, was orphaned when she was barely 2 months old
                  and was rescued by our team. After 4 years of rehabilitation, care and nurturing at our centre (CWRC in
                  Assam)
                  Ganga was released back into the wild in November 2008. Not only did Ganga successfully adapt to her
                  natural
                  habitat, but she also became a mother, giving birth to her first calf in April 2012 in the wild.
                </span>
              </v-flex>

              <v-flex xs12 px-2 pt-3 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>

                  Subsequently, Ganga gave birth to three more rhino calves in June 2015, September 2019, and July 2021.
                  The legacy
                  continued as the first calf born to Ganga in April 2012 was sighted with her own offspring, a male, in
                  November
                  2017. The birth of the second generation, part of a lineage established by Ganga in the wild, is a clear
                  indication of
                  the success of our rhino rehabilitation efforts.
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-3 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  Had she not been rescued, it would have been impossible for Ganga to survive in the wild at that tender
                  age. But we
                  are so glad we found her.
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-10 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  <span style="font-weight:bold">
                    ALLOPARENTING HORNBILLS
                  </span>
                  – Our CWRC team was recently thrilled by an incredible hornbill behaviour. In July of
                  this year, locals rescued an injured hornbill fledgling and handed it over to our veterinarian. The bird
                  had a corneal
                  injury in its left eye and was found all alone. While we treated the bird and placed it in an outdoor
                  recovery cage, we
                  were amazed when a male hornbill arrived with food to feed the fledgling. What made this even more
                  exciting was
                  that this visiting hornbill was one we had successfully rehabilitated and released just last year, as
                  evidenced by the
                  visible identification ring we had placed on its leg.
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-3 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  Moreover, the visiting male hornbill was accompanied by a female partner, and together, the pair began
                  fostering
                  the young one, providing it with a rich insect diet. It was both beautiful and fascinating to witness an
                  orphaned
                  individual, once saved and given a second chance at life at CWRC, now helping foster another orphaned
                  young one.
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-10 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  <span style="font-weight:bold">
                    BEAR CUB DEN
                  </span>
                  – In June 2022, we admitted &#39;Den,&#39; a two-month-old bear cub, to our CBRC center in Arunachal
                  Pradesh. Den had become an orphan when poachers killed his mother for her gall bladder. At the time of
                  rescue,
                  Den was in a pitiful state—emaciated, weak, and frightened. It took nearly a month for the bear cub to
                  stabilize and
                  for the rehabilitation process to commence. Den began regular walks into the forest, accompanied by his
                  animal
                  keeper, to learn foraging and the ways of the wild. The animal keeper, serving as Den&#39;s foster
                  parent, prepared him
                  for a life in the wild, mirroring the care his own mother would have provided. After seven months of
                  nurturing and
                  care at CBRC, Den was ready for a successful release back into the wild in February 2023.
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-3 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  Recently, on August 19, 2023, Den was sighted in close proximity to the &quot;Rhino Anti-Poaching
                  Camp&quot; within the
                  Pakke Tiger Reserve in Arunachal Pradesh, nearly six months after his release. Before being released,
                  Den was fitted
                  with an ear tag, allowing us to identify him. To date, CBRC has successfully rescued and rehabilitated
                  63 bear cubs,
                  returning them to their natural habitat.
                </span>
              </v-flex>

              <!-- 
              <v-flex xs12 px-2 pt-10 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  <span style="font-weight:bold">
                    FEMALE LEOPARD
                  </span>
                  – We keep crossing paths with this female leopard. This leopard was trapped on 01/07/2023 and
                  was released after microchip implantation on 02/07/2023 at 19.8 km away from trapping site
                </span>
              </v-flex> -->
              <v-flex xs12 px-2 pt-3 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  On 21/07/2023 again the same leopard is trapped with an injury in the facial region, where she has moved
                  10.80 km
                  from the release site , and the direction is towards the first trapping area.
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-3 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  This data is only possible because we have been regularly implanting microchip in rescued/trapped
                  leopards
                </span>
              </v-flex>
              <v-flex xs12 px-2 pt-3 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
                <span>
                  These are just a few examples of the many successful wildlife rehabilitations WTI has handled in the
                  past. As we
                  continue our efforts, we invite you to JOIN US and be a part of these incredible legacies. Together, we
                  can
                  contribute to keep wildlife #ForeverWild.
                </span>
              </v-flex>

            </v-layout>

          </v-flex>
          <v-flex xs12 md4 pl-0 pl-md-7 pt-14>
            <v-card elevation="3" v-if="firstCard" class="animate2" id="div2">
              <v-layout wrap justify-center pa-3 pa-sm-0>
                <v-flex xs12 py-3 style="background-color: #f2f2f2; margin-bottom: 1px solid grey">
                  <span class="popregular ml">Help our organization by donating today..!</span>
                </v-flex>
                <!-- <v-flex xs12 py-6>
              <a href="https://www.globalgiving.org/donate/9916/wildlife-trust-of-india/">
                <v-btn class="popregular" large color="#ecb338" style="text-transform: none; color: white">
                  Donate on GlobalGiving
                </v-btn>
              </a>
            </v-flex> -->
                <!-- <v-flex xs10>
              <v-layout wrap>
                <v-flex xs5 align-self-center><v-divider></v-divider></v-flex>
                <v-flex xs2 class="popregular ml">Or</v-flex>
                <v-flex xs5 align-self-center><v-divider></v-divider></v-flex>
              </v-layout>
            </v-flex> -->
                <!-- <v-flex xs12 pb-4><span style="
                  color: #555;
                  font-family: montserratbold;
                  font-size: 20px;
                ">Select Country
              </span></v-flex> -->
                <!-- <v-flex xs12 px-3 px-md-12>
              <v-select background-color="#e27725" solo ref="country" dark label="National Of" placeholder="Country"
                class="center4" :items="regions" :rules="[rules.required]" v-model="country" item-text="name"
                item-value="name" item-color="#FF1313">
              </v-select>
            </v-flex> -->


                <v-flex xs12>

                  <v-layout wrap justify-center>
                    <v-flex xs11 pa-4 style="background-color:#e27725 ;">
                      <span class="popregular" style="font-size: large;color: white;">Donate Now</span>

                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12>
                  <v-layout wrap justify-center class="popregular">
                    <v-flex xs12>
                      <v-layout pb-5 pt-5 wrap justify-center style="cursor: pointer;"
                        @click="(secondCard = true), (firstCard = false), login(), donation = BTN1">
                        <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                          <v-layout wrap>
                            <v-flex align-self-center xs3 pt-2 pb-2>
                              <span>
                                {{ sign }}
                              </span>
                              <span style="font-size:23px">
                                {{ BTN1 }}
                              </span>
                            </v-flex>
                            <v-flex xs8 pt-4 pb-4 text-left align-self-center>
                              <span style="font-size: 14px;">
                                Will take care of communication costs for one enforcement team member for 2 weeks

                              </span>
                            </v-flex>
                            <v-flex align-self-center pt-7 pb-7 xs1 style="background-color:#f8c087">
                              <v-layout wrap justify-center>
                                <v-flex xs11>

                                  <v-icon color="white">mdi-chevron-right</v-icon>

                                </v-flex>
                              </v-layout>

                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>


                      <v-layout pb-5 wrap justify-center style="cursor: pointer;"
                        @click="(secondCard = true), (firstCard = false), login(), donation = BTN2">
                        <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                          <v-layout wrap>
                            <v-flex align-self-center xs3 pt-2 pb-2>
                              <span>
                                {{ sign }}
                              </span>
                              <span style="font-size:23px">
                                {{ BTN2 }}
                              </span>
                            </v-flex>
                            <v-flex xs8 pt-4 pb-4 text-left align-self-center>
                              <span style="font-size: 14px;">
                                Will buy one hidden camera for our enforcement team to gather intelligence

                              </span>
                            </v-flex>
                            <v-flex align-self-center pt-7 pb-7 xs1 style="background-color:#f8c087">
                              <v-layout wrap justify-center>
                                <v-flex xs11>

                                  <v-icon color="white">mdi-chevron-right</v-icon>

                                </v-flex>
                              </v-layout>

                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>


                      <v-layout pb-5 wrap justify-center style="cursor: pointer;"
                        @click="(secondCard = true), (firstCard = false), login(), donation = BTN3">
                        <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                          <v-layout wrap>
                            <v-flex align-self-center xs3 pt-2 pb-2>
                              <span>
                                {{ sign }}
                              </span>
                              <span style="font-size:23px">
                                {{ BTN3 }}
                              </span>
                            </v-flex>
                            <v-flex xs8 pt-4 pb-4 text-left align-self-center>
                              <span style="font-size: 14px;">
                                Will cover logistical costs for one enforcement staff member for a week


                              </span>
                            </v-flex>
                            <v-flex align-self-center pt-7 pb-7 xs1 style="background-color:#f8c087">
                              <v-layout wrap justify-center>
                                <v-flex xs11>

                                  <v-icon color="white">mdi-chevron-right</v-icon>

                                </v-flex>
                              </v-layout>

                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                      <v-layout pb-5 wrap justify-center style="cursor: pointer;"
                        @click="(secondCard = true), (firstCard = false), login(), donation = BTN4">
                        <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                          <v-layout wrap>
                            <v-flex align-self-center xs3 pt-2 pb-2>
                              <span>
                                {{ sign }}
                              </span>
                              <span style="font-size:23px">
                                {{ BTN4 }}
                              </span>
                            </v-flex>
                            <v-flex xs8 pt-4 pb-4 text-left align-self-center>
                              <span style="font-size: 14px;">
                                Will cover 25% of costs towards a software that help that will help monitor suspects
                                movements.

                              </span>
                            </v-flex>
                            <v-flex align-self-center pt-7 pb-7 xs1 style="background-color:#f8c087">
                              <v-layout wrap justify-center>
                                <v-flex xs11>

                                  <v-icon color="white">mdi-chevron-right</v-icon>

                                </v-flex>
                              </v-layout>

                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout pb-5 wrap justify-center style="cursor: pointer;"
                        @click="(secondCard = true), (firstCard = false), login(), donation = BTN5">
                        <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                          <v-layout wrap>
                            <v-flex align-self-center xs3 pt-2 pb-2>
                              <span>
                                {{ sign }}
                              </span>
                              <span style="font-size:25px">
                                {{ BTN5 }}
                              </span>
                            </v-flex>
                            <v-flex xs8 pt-4 pb-4 text-left align-self-center>
                              <span style="font-size: 14px;">
                                Will cover cost of training for one local staff member to gather intelligence.


                              </span>
                            </v-flex>
                            <v-flex align-self-center pt-7 pb-7 xs1 style="background-color:#f8c087">
                              <v-layout wrap justify-center>
                                <v-flex xs11>

                                  <v-icon color="white">mdi-chevron-right</v-icon>

                                </v-flex>
                              </v-layout>

                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout pb-5 wrap justify-center>
                        <v-flex xs12 sm6 md12 lg11 style='background-color: #ffe9d6;'>
                          <v-layout wrap justify-end>
                            <v-flex align-self-center xs4 pt-3 pb-3>
                              <v-text-field type="number" id="my_textbox" height="40px" outlined
                                style="background-color:white" hide-details="true" v-model="donation" class="center">
                                <template v-slot:prepend-inner>
                                  <v-layout justify-center pt-3>
                                    <v-flex xs12 class="align-self-center" style="border-right: 1px dotted grey">
                                      <span style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        ">{{ Cname + sign }}</span>
                                    </v-flex>
                                  </v-layout>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex xs6 pt-3 pb-3 align-self-center>
                              Other Amount
                            </v-flex>
                            <v-flex @click="(secondCard = true), (firstCard = false), login()" xs1
                              style="background-color:#f8c087">
                              <v-layout wrap>
                                <v-flex xs12 align-self-center pt-6 pr-1>
                                  <v-icon color="white">mdi-chevron-right</v-icon>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                    </v-flex>
                    <!-- <v-flex xs12 sm6 md12 lg6 pt-4 pr-3>
  <v-text-field
                type="number"
                id="my_textbox"
                height="60px"
                outlined
                v-model="donation"
                class="center"
              >
                <template v-slot:prepend-inner>
                  <v-layout justify-center pt-3>
                    <v-flex xs12 
                      
                      class="align-self-center"
                      style="border-right: 1px dotted grey"
                    >
                      <span
                        style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        "
                        >{{ Cname + sign }}</span
                      >
                    </v-flex>
                  </v-layout>
                </template>
              </v-text-field>
</v-flex>
<v-flex  xs12 sm6 md12  lg5 pt-4>
  <v-btn
                @click="(secondCard = true), (firstCard = false) , login()"
                height="60px"
                color="#e27725"
                dark
                style="font-size: 24px; text-transform: none"
                block
                >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
              >
</v-flex> -->
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <!-- <v-layout wrap pt-8 pa-8 class="popregular">
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN1"
                    outlined
                    block
                    height="60px"
                    color="#e27725"
                    style="font-size: 20px"
                    dark
                    >{{ sign }}{{ BTN1 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN2"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN2 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN3"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN3 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    id="my_button"
                    @click="toTextField()"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 16px; text-transform: none"
                    block
                    >Any other amount</v-btn
                  >
                </v-flex>
              </v-layout> -->
                </v-flex>
                <!-- <v-flex xs12 px-10>
              <v-text-field
                type="number"
                id="my_textbox"
                height="70px"
                outlined
                v-model="donation"
                class="center"
              >
                <template v-slot:prepend-inner>
                  <v-layout justify-center pt-3>
                    <v-flex xs12 
                      
                      class="align-self-center"
                      style="border-right: 1px dotted grey"
                    >
                      <span
                        style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        "
                        >{{ Cname + sign }}</span
                      >
                    </v-flex>
                  </v-layout>
                </template>
              </v-text-field>
            </v-flex> -->
                <!-- <v-flex xs12 sm6 py-8>
              <v-btn
                @click="(secondCard = true), (firstCard = false) , login() "
                height="60px"
                color="#e27725"
                dark
                style="font-size: 24px; text-transform: none"
                block
                >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-flex> -->
                <v-flex class="popregular" xs12 py-5 text-center style="background-color: #f2f2f2">
                  <span><v-icon small>mdi-lock</v-icon> </span>
                  <span>Secure Donation</span>
                </v-flex>
              </v-layout>
            </v-card>

            <v-card elevation="3" v-if="secondCard">
              <v-form ref="file">
                <v-layout wrap justify-center pa-3 class="animate" id="div1">
                  <v-flex xs12>
                    <v-layout wrap style="background-color: #f2f2f2">
                      <v-flex xs1 align-self-center style="background-color: #f2f2f2">
                        <v-icon @click="firstCard = true, secondCard = false">mdi-chevron-left</v-icon>
                      </v-flex>
                      <v-flex xs10 py-3 style="
                      background-color: #f2f2f2;
                      margin-bottom: 1px solid grey;
                    ">
                        <span class="popregular ml">Add Your Information.</span>
                      </v-flex>
                      <v-flex xs1 py-3>
                        <v-spacer></v-spacer>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 py-6 text-center style="color: grey">
                    <span class="popregular " style="font-size:20px">Who's giving today?</span>
                    <br />

                    <i>
                      <span class="popregular">We’ll never share this information with anyone</span></i>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-text-field class="center2" type="number" ref="amount" color="#828282" placeholder="Amount"
                      label="Amount" :prepend-inner-icon="sign" outlined rounded :rules="[rules.required]"
                      v-model="donation"></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-text-field ref="firstname" color="#828282" prepend-inner-icon="mdi-account" label="First Name"
                      rounded outlined class="center2" :rules="[rules.required]" v-model="firstname"></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-text-field ref="lastname" color="#828282" prepend-inner-icon="mdi-account" label="Last Name"
                      rounded outlined class="center2" :rules="[rules.required]" v-model="lastname"></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-text-field ref="email" color="#828282" prepend-inner-icon="mdi-email" outlined rounded
                      class="center2" label="Email" :rules="[rules.email]" v-model="email"></v-text-field>
                  </v-flex>
                  <template v-if="taxExemption == true">
                    <v-flex xs12 px-5>
                      <v-text-field color="#828282" prepend-inner-icon="mdi-pencil" outlined rounded class="center2"
                        label="Address" :rules="[rules.required]" v-model="Newaddress"></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                        label="City" :rules="[rules.required]" v-model="Newcity"></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                        label="Pincode" :rules="[rules.required]" v-model="Newpincode"></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                        label="Pan Number" :rules="[rules.required]" v-model="Newpannumber"></v-text-field>
                    </v-flex>
                  </template>
                  <v-flex xs12 px-5 pt-2>
                    <v-checkbox v-model="taxExemption" hide-details="" class="LBL" :value="true"
                      label="I wish to claim tax exemption against this donation"></v-checkbox>
                  </v-flex>

                  <v-flex xs12 px-5>
                    <v-checkbox v-model="isanonymous" hide-details="" class="LBL" :value="true"
                      label="Make my donation anonymous "></v-checkbox>
                  </v-flex>

                  <v-flex xs12 px-5>
                    <v-checkbox v-model="subscribe" hide-details="" class="LBL" :value="true" label="
                Subscribe to our newsletter for inspiring stories from our
                projects"></v-checkbox>
                  </v-flex>

                  <v-flex xs12 sm6 py-8>
                    <v-btn @click="validate()" height="60px" color="#e27725" dark style="
                    font-size: 20px;
                    text-transform: none;
                    font-family: poppinsregular;
                  " block>Donate Now
                    </v-btn>
                  </v-flex>

                  <v-flex class="popregular" xs12 py-5 text-center style="background-color: #f2f2f2">
                    <span><v-icon small>mdi-lock</v-icon> </span>
                    <span>Secure Donation</span>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 text-center>
        <v-card>

        </v-card>
        <v-layout wrap justify-center>
          <v-flex xs11 px-2 pt-3 pb-6 pb-sm-4 pb-md-10 style="text-align:justify" class="popregular medium">


            <v-card style="background-color: #D3D3D3;">
              <v-layout wrap>
                <v-flex pa-6>
                  <span>
                    PS – While the names &#39;Ganga,&#39; &#39;Den,&#39; and others are assigned for communication
                    purposes
                    and human reference, it&#39;s
                    important to note that these names do not represent personal identities for the animals. Instead, to
                    track and
                    monitor the progress of a rehabilitated animal, we employ identification methods like numbered tags,
                    microchips,
                    or other non-personal identifiers.
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Newaddress: '',
      Newcity: "",
      Newpincode: "",
      Newpannumber: "",
      donation2: null,
      //inherited varibles from donation Card
      BTN1: 10,
      BTN2: 25,
      BTN3: 50,
      BTN4: 75,
      BTN5: 100,
      sign: "₹",
      Cname: "INR",
      country: "India",
      amount: null,
      firstname: null,
      lastname: null,
      mobile: null,
      email: null,
      address: null,
      city: null,
      state: null,
      postalcode: null,
      panno: null,
      hearaboutus: "",
      comments: null,
      isanonymous: false,
      subscribe: false,
      taxExemption: false,
      symbol: "₹",
      regionssymbol: [],
      message: "",
      resource: null,
      regions: [],
      //end of inherited variables from donationCard
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      donation: null,
      firstCard: true,
      secondCard: false,
      radio: null,
      showSnackBar: false,
      msg: "",
      ServerError: false,
      timeout: 3000,
      appLoading: false,
    };
  },
  watch: {
    country() {
      if (this.country != "India") {
        this.BTN1 = 10;
        this.BTN2 = 25;
        this.BTN3 = 50;
        this.BTN4 = 75;
        this.BTN5 = 100;

        this.sign = "$";
        this.Cname = "USD";
        this.donation = this.BTN1;
        //  console.log("true not india")
      } else {
        this.BTN1 = 10;
        this.BTN2 = 25;
        this.BTN3 = 50;
        this.BTN4 = 75;
        this.BTN5 = 100;
        this.sign = "₹";
        this.Cname = "INR";

        this.donation = this.BTN1;

        //  console.log("false india")
      }
    },
  },
  beforeMount() {
    axios({
      method: "GET",
      url: "/country/all",
    })
      .then((response) => {
        this.regions = response.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.getData();
  },
  methods: {
    navigateToHome() {
      this.$router.push("/");
    },
    scrollToFAQ() {
      // Scroll to the FAQ section on the Home page
      window.location.href = '/#faq-section';
    },
    login() {
      this.$gtag.event('login', { method: 'Google' })
    },
    toTextField() {
      this.donation = null;
      document.getElementById("my_button").onclick = function () {
        document.getElementById("my_textbox").focus();
      };
    },
    validate() {
      if (this.$refs.file.validate()) {
        var don = {};
        this.appLoading = true;
        don["country"] = this.country;
        don["amount"] = this.donation;
        don["firstname"] = this.firstname;
        don["lastname"] = this.lastname;
        // don["donationtype"] = this.$route.query.DonationName;
        don["email"] = this.email;
        don["isanonymous"] = this.isanonymous;
        don["subscribe"] = this.subscribe;
        don["address"] = this.Newaddress;
        don["city"] = this.Newcity;
        // don["pincode"] = this.Newpincode;
        // don["panNumber"] = this.Newpannumber;
        don["taxExemption"] = this.taxExemption;
        don["postalcode"] = this.Newpincode;
        don["panno"] = this.Newpannumber;
        // taxExemption
        axios({
          method: "post",
          url: "/payment/initiate",
          data: don,
        })
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("currencySymbol", this.symbol);
              this.appLoading = false;
              this.$router.push(
                "/payment/?id=" +
                response.data.id +
                "&country=" +
                this.country +
                "&event=" +
                this.$route.query.DonationName
              );
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch(() => {
            this.appLoading = false;
            this.ServerError = true;
          });
      } else {
        this.showSnackBar = true;
        this.msg = "Please complete your form";
      }
    },
  },
};
</script>
<style  scoped>
.LBL>>>label {
  font-family: poppinsregular !important;
  font-size: 12px !important;

}

.v-text-field--outlined>>>fieldset {
  border-color: grey;
  border-radius: 5px;
}

.v-radio>>>label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}

.center>>>input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 32px;
}

.center2>>>label {
  text-align: center;
  font-family: poppinsregular;
  font-size: 20px;
}

.center4 {
  text-align: center !important;
  font-family: poppinsregular !important;

}

.boldR>>>label {
  font-family: poppinsbold;
}

.animate {
  position: relative;
  animation: mymove 0.25s;
}

.animate2 {
  position: relative;
  animation: mymove2 0.25s;
}

#div2 {
  animation-timing-function: linear;
}

@keyframes mymove2 {
  from {
    left: -200px;
  }

  to {
    left: 0px;
  }
}

@keyframes mymove {
  from {
    right: -500px;
  }

  to {
    right: 0px;
  }
}

#div1 {
  animation-timing-function: linear;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear;
  /* Safari */
  animation: spin 2s linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.v-checkbox>>>label {
  font-family: poppinsregular;
}
</style>